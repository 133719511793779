import React, { Component } from 'react';
import { graphql } from 'gatsby';
import { withSize } from 'react-sizeme';

import Layout from '../components/layout';
import SEO from '../components/seo';
import PressStyled from '../components/page-styles/press-styles';

import Hero from '../components/hero';
import InfoCard from '../components/infoCard';

import heroImageDesktop from '../images/hero-press-desktop.png';



class PressReleaseTemplate extends Component {
    render() {
        const { path, size } = this.props;
        let pressRelease = this.props.data.allSitePage.edges[0].node.pageContext;
        return (
            <Layout path={path}>
                <PressStyled>
                    <SEO title="Press" />
                    <Hero
                        headerText="Press"
                        background={
                            size.width > 768
                                ? heroImageDesktop
                                : heroImageDesktop
                        }
                        showLogo={false}
                        isSubPage={true}
                    ></Hero>
                    <article>
                        <header>{pressRelease.title}</header>
                        {pressRelease.tagline ? (
                            <p className="tagline">{pressRelease.tagline}</p>
                        ) : null}
                        <span>
                            {pressRelease.location} ({pressRelease.shortDate})
                        </span>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: pressRelease.body,
                            }}
                        />
                    </article>
                    <section>
                        <InfoCard
                            className="info-card"
                            title="Press Kit"
                            link="/Pilot-Company-Press-Kit.zip"
                            linkIsExternal
                            buttonText="Download Now"
                        />
                        <InfoCard
                            className="info-card"
                            title="Media Inquiries"
                            copy="For media inquires, please contact <a href='mailto:media.relations@pilotcompany.com'>media.relations@pilotcompany.com</a>"
                        />
                    </section>
                </PressStyled>
            </Layout>
        );
    }
}

export const query = graphql`
    query($path: String!) {
        allSitePage(filter: { path: { eq: $path } }) {
            edges {
                node {
                    pageContext
                }
            }
        }
    }
`;
export default withSize()(PressReleaseTemplate);
