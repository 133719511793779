import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from './button';

const InfoCardStyled = styled.aside`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 16px;
    padding: 47px 30px;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: ${props =>
            props.link == undefined ? '#ABC7CA' : '#fff'};

        .info-card__copy {
            color: ${props =>
                props.link == undefined ? '#0b1822' : '#6c7279'};
        }
    }

    .info-card__eyebrow {
        font: 400 11px purista-web, sans-serif;
        font-style: italic;
        text-transform: uppercase;
        letter-spacing: 1.89px;
        color: #53565a;
    }

    .info-card__title {
        margin-bottom: 16px;
        color: #0b1822;
        font: 700 24px purista-web, sans-serif;
        font-style: italic;
        letter-spacing: 1.8px;
        line-height: 29px;
        text-transform: uppercase;

        @media screen and (max-width: 1024px) {
            font-size: 20px;
        }
    }

    .info-card__copy {
        color: #6c7279;
        font: 400 14px roboto, sans-serif;
        line-height: 28px;

        a {
            color: #0b1822;
            font-weight: 500;
        }

        @media screen and (max-width: 1024px) {
            font-size: 14px;
            line-height: 26px;
        }
    }
`;

const InfoCard = ({
    className,
    title,
    copy,
    link,
    linkIsExternal,
    buttonText,
}) => (
    <InfoCardStyled className={className} link={link}>
        <p className="info-card__title">{title}</p>
        <p
            className="info-card__copy"
            dangerouslySetInnerHTML={{ __html: copy }}
        />
        {link ? (
            <Button
                link={link}
                isExternal={linkIsExternal}
                buttonText={buttonText}
                buttonType="primary"
            />
        ) : null}
    </InfoCardStyled>
);

InfoCard.propTypes = {
    title: PropTypes.string.isRequired,
    copy: PropTypes.string,
    link: PropTypes.string,
    linkText: PropTypes.string,
    isExternal: PropTypes.bool,
};

export default InfoCard;
