import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';

const ButtonStyled = styled.div`
    display: inline-flex;

    a {
        border-radius: 6px;
        padding: 16px 64px;
        background-color: ${props =>
            props.buttontype == 'primary' ? '#ABC7CA' : '#fff'};
        font: 700 14px purista-web, sans-serif;
        letter-spacing: 2.19px;
        font-style: italic;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        border: 1px solid #abc7ca;
        box-sizing: border-box;
        color: #0b1822;
        text-decoration: none;
        height: 100%;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: #fff;
            border: 1px solid #abc7ca;
        }
    }
`;

const Button = ({ link, buttonText, buttonType, isExternal }) => (
    <ButtonStyled
        buttontype={buttonType}
        className={isExternal ? 'external-link' : 'internal-link'}
    >
        {!isExternal ? (
            <Link to={link}>{buttonText}</Link>
        ) : (
            <a href={link} target="_blank" rel="noopener noreferrer">
                {buttonText}
            </a>
        )}
    </ButtonStyled>
);

Button.propTypes = {
    buttonText: PropTypes.string.isRequired,
    buttonType: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    isExternal: PropTypes.bool.isRequired,
};

Button.defaultProps = {
    isExternal: false,
};

export default Button;
