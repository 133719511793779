import styled from 'styled-components';

const PressStyled = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;

    .previews {
        display: flex;
        flex-direction: column;
        align-items: center;

        article {
            margin-right: 0;
        }
    }

    article {
        width: calc(100% - 88px);
        max-width: 710px;
        margin: 48px 40px 60px 0;

        @media screen and (min-width: 768px) {
            margin-right: 0;
        }

        @media screen and (min-width: 1024px) {
            width: 90%;
        }

        header {
            font-size: 28px;
            font-weight: 700;
            font-style: italic;
            letter-spacing: 1.47px;

            @media screen and (min-width: 768px) {
                font-size: 38px;
                letter-spacing: 2px;
            }
        }

        .tagline {
            margin-top: 20px;
            font-style: italic;
        }

        span {
            display: block;
            margin: 24px 0;
            font: 400 16px roboto, sans-serif;
            font-style: italic;
            text-transform: uppercase;
            letter-spacing: 0.84px;
            color: #4a4f53;

            @media screen and (min-width: 768px) {
                font-size: 18px;
                letter-spacing: 0.95px;
            }
        }

        .sub-title {
            text-decoration: underline;
        }

        p {
            margin-bottom: 32px;
            color: #6b727a;
            font-size: 16px;
            font-family: roboto, sans-serif;
            line-height: 32px;

            a {
                font-weight: 500;
                color: #0b1822;
            }
        }

        ul {
            margin-left: 60px;

            b {
                color: #0b1822;
            }
        }
    }

    .info-card {
        width: calc(100% - 50px);
        max-width: 365px;
        @media screen and (min-width: 768px) {
            width: 365px;
        }

        .external-link {
            margin-top: 16px;

            a {
                width: 100%;
                text-align: center;
            }
        }
    }
`;

export default PressStyled;
